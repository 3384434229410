<template>
    <a-table :columns="columns" :data-source="data" bordered size="small" :scroll="{ x: 1500 }">
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiProductOrderWorkOrderList, apiProductOrderDetail } from "@/api";
import { getStatusColor } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "物料编码",
        dataIndex: "materialCode",
    },
    {
        title: "物料名称",
        dataIndex: "materialName",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "物料条码",
        dataIndex: "materialBarCode",
    },
    {
        title: "物料厂商",
        dataIndex: "supplier",
    },
    {
        title: "来料批次号",
        dataIndex: "batchNo",
    },
    {
        title: "绑定时间",
        dataIndex: "createdTime",
    },
    {
        title: "绑定工序",
        dataIndex: "workStepName",
    },
    {
        title: "数量",
        dataIndex: "count",
    },
    {
        title: "操作人",
        dataIndex: "creatorDisplayName",
    },
];

export default defineComponent({
    props: ["data"],
    setup() {
        return {
            columns,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
