<template>
    <a-table
        class="ant-table-striped"
        size="middle"
        :columns="columns"
        :data-source="data"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
    >
        <!-- <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
        </template> -->
        <!-- <template v-slot:[item]="scope" v-for="item in renderArr">
            <slot :name="item" :scope="scope" v-bind="scope || {}"></slot>
        </template> -->
    </a-table>
</template>
<script>
import { defineComponent, useSlots, reactive, toRefs } from "vue";
export default defineComponent({
    props: {
        columns: {
            default: [],
        },
        data: {
            default: [],
        },
    },
    setup() {
        const slots = useSlots();
        const renderArr = Object.keys(slots);
        const data = reactive({
            renderArr,
        });
        
        return {
            ...toRefs(data),
        };
    },
});
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
