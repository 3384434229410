<template>
    <div class="container">
        <Detail v-model:detailId="detailId" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <!-- <Table :columns="columns" :data="data"></Table> -->
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 2000 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'projectName'">
                        {{ record?.workOrderInfo?.projectName }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailId = record.id">详情</a>
                        </a-space>
                    </template>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import Table from "@/components/Table";
import { formateMoment, jsonToUrlParams, getStatusColor } from "@/utils/common";
import { apiCjFinishProduct } from "@/api";

const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "产成品条码",
        dataIndex: "barcode",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "生产批次号",
        dataIndex: "productionBatchNo",
    },
    {
        title: "工单号",
        dataIndex: "cjWorkOrderCode",
    },
    {
        title: "工单类型",
        dataIndex: "productionLineZh",
    },
    {
        title: "生产订单号",
        dataIndex: "orderCode",
    },
    {
        title: "完成时间",
        dataIndex: "completeTime",
    },
    {
        title: "操作人",
        dataIndex: "completeOperatorDisplayName",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
        Table,
    },
    setup() {
        const state = reactive({
            detailId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params, isJumpWithCode) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiCjFinishProduct({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: "COMPLETED",
                    orderType: "COMPLETED_TIME_DESC",
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
                if (isJumpWithCode) {
                    state.detailId = state.data[0]?.id;
                }
            }
        };

        const search = (params, isJumpWithCode) => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params, isJumpWithCode);
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            getTabelData,
            formateMoment,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
