<template>
    <a-table :columns="columns" :data-source="data" bordered size="small">
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiProductOrderWorkOrderList, apiProductOrderDetail } from "@/api";
import { getStatusColor } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "操作内容",
        dataIndex: "operation",
    },
    {
        title: "操作人",
        dataIndex: "operator",
    },
    {
        title: "操作时间",
        dataIndex: "operationTime",
    },
];

export default defineComponent({
    props: ["data"],
    setup() {
        return {
            columns,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
