<template>
    <a-drawer
        v-model:visible="visible"
        title="产成品详情"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane :key="1" tab="基础信息">
                <BaseInfo :data="detailInfo" />
            </a-tab-pane>
            <a-tab-pane :key="2" tab="物料绑定记录">
                <BindingRecord :data="detailInfo?.materialBindDetails" />
            </a-tab-pane>
            <a-tab-pane :key="3" tab="操作记录">
                <OperateRecord :data="detailInfo?.operates" />
            </a-tab-pane>
        </a-tabs>
    </a-drawer>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiCjFinishProductDetailById } from "@/api";
import Select from "@/components/Select";
import { getStatusColor, formateMoment } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { baseURL } from "@/utils/config";
import BaseInfo from "./baseInfo";
import BindingRecord from "./bindingRecord";
import OperateRecord from "./operateRecord";
export default defineComponent({
    props: ["detailId"],
    emits: ["eventUpdateOk", "update:detailId"],
    components: {
        Select,
        DownOutlined,
        BaseInfo,
        BindingRecord,
        OperateRecord,
    },
    setup(props, { emit }) {
        const { getters } = useStore();
        const state = reactive({
            activeKey: 1,
            visible: false,
            detailInfo: null,
            token: getters["user/token"],
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:detailId", null);
        };

        const getIndexData = async id => {
            let res = await apiCjFinishProductDetailById(id);
            if (res.status === "SUCCESS") {
                state.detailInfo = res.data;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            formateMoment,
            getStatusColor,
            baseURL,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
.members-box {
    display: flex;
    flex-wrap: wrap;
}
</style>
